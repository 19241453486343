import React from 'react';
import { Box, TextField, Hidden, Slide } from '@material-ui/core';
import FormButton from './FormButton';
import useStyles from './form.css';

const SubscribeForm = () => {

    const formRef = React.useRef();
    const confirmationRef = React.useRef();

    const [pending, setPending] = React.useState(false);
    const [subscribed, setSubscribed] = React.useState(null);

    const classes = useStyles({ subscribed });

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setPending(true);

            const email = formRef.current.value;

            const response = await fetch('/.netlify/functions/subscribeMailingList', {
                method: 'POST',
                body: JSON.stringify({ email }),
            });
            // success
            formRef.current.value = '';
            // confirmation animation
            confirmationRef.current.innerHTML = "Thank you for subscribing!"
            setSubscribed('success');
        } catch (err) {
            console.error(err);
            confirmationRef.current.innerHTML = "Something went wrong. Please try again."
            setSubscribed('error');
        } finally {
            setPending(false);
            setTimeout(() => {
                setSubscribed(null);
            }, 8000);
        }
    }

    return (
        <Box display="block">
            <form onSubmit={handleSubmit}>
                <TextField
                    id="subscribe-email"
                    inputRef={formRef}
                    label="Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    required
                    className={classes.FormField}
                    size="small"
                />
                <Hidden smDown>
                    <FormButton type="submit" style={{ marginLeft: '.5rem' }} disabled={pending}>Submit</FormButton>
                </Hidden>
                <Hidden mdUp>
                    <FormButton type="submit" style={{ minWidth: 0, marginLeft: '-.5rem' }} disabled={pending}>&#8594;</FormButton>
                </Hidden>
            </form>
            <Box ref={confirmationRef} mt={2} color="primary.main" display="block" className={classes.confirmationAnimation}>
                Thank you for subscribing!
            </Box>
        </Box>
    );
}

export default SubscribeForm;