import React from 'react';

export const useLocalStorage = function(storageKey, initialValue){

    const setLocalStorage = React.useCallback((value) => {
        if (typeof window !== 'undefined') {
            localStorage.setItem(storageKey, JSON.stringify(value));
        }
    }, []);

    const getLocalStorage = React.useCallback(() => {
        if (typeof window !== 'undefined') {
            return JSON.parse(localStorage.getItem(storageKey))
        }
    }, []);

    const [state, setState] = React.useState(typeof initialValue !== 'undefined' ? initialValue : getLocalStorage());

    // sync local storage with state
    React.useEffect(() => {
        setLocalStorage(state);
    }, [state]);

    return [state, setState];

}